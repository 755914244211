import { useUser } from '../composables/apis/user'
import '../static/script/intercom.js'
import { useMiddlewareHelpers } from '../composables/apis/useMiddlewareHelpers'
import { ROUTES_NAMES } from '../routes'
import { RouteRecordName } from '#vue-router'

const authPages = [
  ROUTES_NAMES.login,
  ROUTES_NAMES.signup,
  ROUTES_NAMES.otp,
  ROUTES_NAMES.forgotPassword,
] as Array<RouteRecordName>

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { getCollabRedirectRoute } = useMiddlewareHelpers()

  if (!useUser().isLoggedIn.value && !authPages.includes(to.name as RouteRecordName)) {
    // remove this check as users must be authenticated to visit this page
    // && !to.fullPath.includes('/auth/business')
    return navigateTo(
      (await getCollabRedirectRoute(from)) || {
        path: '/auth/login',
      },
    )
  }

  if (window.Intercom) {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'xd9igd3d',
    })
    window.Intercom('update')
  }
})
